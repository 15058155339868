import '../styles/Quote.css';

const Quote = () => (
  <div className="mainQuote">
    <p className="theQuote">
      Mathematics is not about numbers, equations, computations, or algorithms:
      it is about understanding.
      <em>- William Paul Thurston</em>
    </p>
  </div>
);

export default Quote;
