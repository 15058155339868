import '../styles/Home.css';

const Home = () => (
  <>
    <div className="homeContainer">
      <h1 className="welcome">Welcome to our page!</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed
        faucibus urna. Sed tincidunt urna vel massa scelerisque interdum. Fusce
        ultrices elementum consequat. facilisis, Lorem ipsum dolor sit amet,
        consectetur adipiscing elit. Nunc sed faucibus urna. Sed tincidunt urna
        vel massa scelerisque interdum. Fusce ultrices elementum consequat.
        facilisis, massa ut eleifend facilisis, urna sem sodales felis , et
        fermentum urna elit at ex. Mauris id dolor quam.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed
        faucibus urna. Sed tincidunt urna vel massa scelerisqu e interdum. Fusce
        ultrices elementum consequat. Vestibulum ante ipsum primis in faucibus
        orci luctus et ultrices posuere cubilia curae; In sed porttitor mauris.
        Praesent blandit, nibh non molestie bibendum, lacus metus vulputate
        metus, sed tempus eros lectus eget magna. Mauris quis ultrices nulla,
        efficitur ullamcorper leo. Proin sit amet leo sagittis, placerat orc
      </p>
    </div>
  </>
);

export default Home;
